<template>
  <!-- <v-container id="user-profile-view" fluid tag="section"> -->
  <div>
    <v-form @submit.prevent="getShifts">
      <v-row>
        <v-col cols="12" lg="6">
          <v-form
            v-if="!$store.state.client.data || !client.enabled"
            @submit.prevent="
              () => {
                getClients()
                client.term = ''
              }
            "
          >
            <atoms-text-field
              label="Cari Client"
              :no-label="true"
              v-model="client.term"
              appendIcon="mdi-magnify"
              :appendAction="
                () => {
                  getClients()
                  client.term = ''
                }
              "
            />
          </v-form>
          <atoms-text-field v-else label="Cari Client (Tekan enter/icon cari pada kolom ini)" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      client = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      areas = {
                        items: null,
                        value: null,
                      }
                      locations = {
                        items: null,
                        value: null,
                      }
                      getShifts()
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="client.value"
                  :items="$store.state.client.data.map((x) => x.name) || []"
                  placeholder="Pilih Client"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <atoms-text-field :noLabel="true">
            <v-autocomplete
              placeholder="Pilih Area"
              class="rounded-lg"
              :items="getAreaItems"
              v-model="getAreaName"
              :menu-props="{ offsetY: true }"
              no-data-text="Tidak ada"
              :item-text="(_val) => `${_val.title} ${(_val.inactive && '(Tidak Aktif)') || ''}`"
              :item-value="(_val) => _val.title"
              hide-details
              solo
              dense
              flat
              required
            >
              <template #prepend>
                <atoms-button @click="getAreaName = null" class="rounded-none" icon
                  ><v-icon>mdi-close</v-icon></atoms-button
                >
              </template>
            </v-autocomplete>
          </atoms-text-field>
        </v-col>
        <v-col cols="12" lg="2">
          <atoms-text-field :noLabel="true">
            <v-autocomplete
              class="rounded-lg"
              placeholder="Pilih Lokasi"
              :items="getAreaName === $status.semua ? [$status.semua] : getLocItems"
              :item-text="(_val) => `${_val.name} ${(_val.inactive && '(Tidak Aktif)') || ''}`"
              :item-value="(_val) => _val.name"
              v-model="getLocName"
              :menu-props="{ offsetY: true }"
              no-data-text="Tidak ada"
              hide-details
              solo
              flat
              dense
              required
            >
              <template #prepend>
                <atoms-button @click="getLocName = null" class="rounded-none" icon
                  ><v-icon>mdi-close</v-icon></atoms-button
                >
              </template>
            </v-autocomplete>
          </atoms-text-field>
        </v-col>
        <v-col cols="12" lg="2" class="d-flex justifyx-space-between">
          <atoms-button type="submit" style="width: 100%" class="primary"
            ><v-icon left>mdi-magnify</v-icon>Cari Shift</atoms-button
          >
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Dari Tanggal"
                :modal="from.modal"
                :value="from.value"
                @change="
                  (modal, value) => {
                    from.modal = modal
                    from.value = value
                  }
                "
              />
            </v-col>
            <v-col cols="12" md="6">
              <atoms-date-picker
                label="Sampai Tanggal"
                :modal="to.modal"
                :value="to.value"
                @change="
                  (modal, value) => {
                    to.modal = modal
                    to.value = value
                  }
                "
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <div>
      <div v-if="loadingListShifts" class="mt-4">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </div>
      <div v-else>
        <div v-if="listShifts && listShifts.length > 0">
          <!-- <atoms-text class="pa-2"
            ><v-icon>mdi-magnify</v-icon>Hasil Pencarian, {{ items.count }} data
            ditemukan</atoms-text
          > -->
          <div>
            <atoms-text class="mb-4"
              ><v-icon left>mdi-magnify</v-icon> Sebanyak
              {{ (listShiftsPagesLength - 1) * limit + listShifts.length || 0 }}
              shifts ditemukan!</atoms-text
            >
          </div>
          <div
            v-for="({ ...item }, i) in listShifts || []"
            :key="i"
            :class="['accent', 'rounded-lg pa-4 mb-2']"
            :style="{
              'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
            }"
          >
            <v-row align="center" justify="space-between">
              <!-- <v-col class="shrink">
                <v-avatar color="grey lighten-4 rounded-lg">
                  <atoms-image
                    :enableZoom="true"
                    v-if="rest.photo"
                    :src="rest.photo"
                  />
                  <v-icon light v-else>mdi-account</v-icon></v-avatar
                >
              </v-col> -->
              <v-col cols="12" lg="5">
                <atoms-text :auto="true" class="font-weight-bold"
                  >Shift {{ $toCapitalize(item.title || '-') }}</atoms-text
                >
                <atoms-text class="primary--text">{{ (item.client && item.client.name) || 'Perusahaan -' }}</atoms-text>
              </v-col>
              <v-col cols="12" lg="5" align="start">
                <atoms-text class="font-weight-bold">Rentang : </atoms-text>

                <atoms-text v-if="item.startDate && item.endDate" class="primary--text">
                  {{ $moment(item.startDate || new Date()).format('dddd, DD MMMM YYYY') }}
                  -
                  {{ $moment(item.endDate || new Date()).format('dddd, DD MMMM YYYY') }}
                </atoms-text>
                <div v-else>-</div>
              </v-col>

              <!-- <v-col align="center" v-if="rest.captain">
                <v-chip small class="rounded-lg" color="primary">Kapten</v-chip>
              </v-col> -->
              <!-- <v-col cols="2" lg="1" class="shrink">
                <v-menu offset-x class="rounded-xl">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn depressed icon v-bind="attrs" v-on="on"
                      ><v-icon>mdi-dots-vertical</v-icon></v-btn
                    >
                  </template>
                  <v-list
                    nav
                    dense
                    elevation="0"
                    class="text-center rounded-lg"
                  >
                     <v-list-item
                      link
                      @click="showEdit = rest"
                      class="rounded-lg px-4"
                    >
                      <v-icon left>mdi-cash</v-icon>
                      <v-list-item-title class="px-2"
                        >Edit Saldo</v-list-item-title
                      >
                    </v-list-item> 
                    <v-list-item link class="rounded-lg px-4">
                      <v-icon left>mdi-clock-outline</v-icon>
                      <v-list-item-title class="px-2"
                        >Riwayat Saldo</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col> -->
            </v-row>
            <v-container fluid class="px-0 pb-0">
              <atoms-text>Nomor Kuotasi : {{ (item.quotation && item.quotation.no) || '-' }}</atoms-text>
              <atoms-text>Area : {{ (item.area && item.area.title) || item.areaTitle || '-' }}</atoms-text>
              <atoms-text>Lokasi : {{ item.location }}</atoms-text>
              <atoms-text>Jumlah Pekerja / PHL : {{ item.amount }}</atoms-text>
              <atoms-text>Hari Kerja : {{ item.quantity }}</atoms-text>
              <atoms-text>Gaji Per Hari : {{ $idCurrency(item.priceBase || 0) }}</atoms-text>
              <atoms-text
                >Pekerja Ditambahkan : {{ (item.assignedWorkerIds && item.assignedWorkerIds.length) || 0 }}</atoms-text
              >
              <div class="d-flex flex-nowrap">
                <atoms-text
                  >Pekerja Terdaftar :
                  {{ (item.workerIds && $addSeparator(item.workerIds.length)) || 'Belum Ada' }}</atoms-text
                >
                <v-chip
                  v-if="!$vuetify.breakpoint.smAndDown && item.workerIds && $addSeparator(item.workerIds.length)"
                  @click="showWorkerList = item"
                  small
                  class="rounded-lg mx-2"
                  >Lihat Daftar Pekerja</v-chip
                >
              </div>
              <br />

              <atoms-button v-if="item.qrCode" @click="openDetail = item" class="text-center rounded-lg"
                ><v-icon left>mdi-qrcode</v-icon>QR Code</atoms-button
              >
              <br />
              <br />
              <atoms-button @click="openShiftAssignment = item" class="text-center rounded-lg"
                >Tambahkan Pekerja ke shift</atoms-button
              >
              <br />
              <atoms-button
                v-if="item.assignedWorkerIds && $addSeparator(item.assignedWorkerIds.length)"
                @click="showAssignedWorkerList = item"
                class="mt-5"
                >Lihat Daftar Pekerja Ditambahkan</atoms-button
              >
              <atoms-button
                v-if="$vuetify.breakpoint.smAndDown && item.workerIds && $addSeparator(item.workerIds.length)"
                @click="showWorkerList = item"
                class="mt-2"
                style="width: 100%"
                >Lihat Daftar Pekerja</atoms-button
              >

              <!-- <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Total Gaji :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ $idCurrency(item.totalBase) }}
                        </p>
                      </div> -->
              <!-- <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Gaji yang Sudah di-bayarkan :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.supervisorApproval }}
                        </p>
                      </div> -->
            </v-container>
          </div>
          <v-row justify="center">
            <v-col cols="8">
              <v-container class="max-width">
                <v-pagination class="my-4" v-model="page" :length="listShiftsPagesLength"></v-pagination>
              </v-container>
            </v-col>
          </v-row>
        </div>
        <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
          <div class="text-center">
            <atoms-image :width="250" src="/assets/missing.svg" />
            <atoms-title :h3="true" class="mt-6">Tidak ada shift yang ditemukan!</atoms-title>
          </div>
        </molecules-wrapper>
      </div>
    </div>

    <!-- <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card class="text-center mt-n4">
          <v-card-text class="text-center">
            <h4 class="text-h4 mb-5 text-left text--primary font-weight-medium">
              Filter Shift
            </h4>
            <v-form @submit.prevent="/* only for prevent form */">
              <v-autocomplete
                v-model="filter.clientId"
                label="Klien"
                placeholder="Masukkan Nama Klien Lalu Klik Enter"
                :items="clients"
                item-text="name"
                item-value="_id"
                :loading="loadingGetClients"
                outlined
                dense
                no-data-text=""
                @keydown.enter="getClients"
                @change="getAreas"
              />
              <v-autocomplete
                v-model="filter.areaId"
                label="Area"
                :items="areas"
                item-text="title"
                item-value="_id"
                :loading="loadingGetAreas"
                outlined
                dense
                no-data-text="Tidak ada"
                @change="getLocations"
              />
              <v-autocomplete
                v-model="filter.locationId"
                label="Lokasi"
                :items="locations"
                item-text="name"
                item-value="_id"
                :loading="loadingGetLocations"
                outlined
                dense
                no-data-text="Tidak ada"
              />
              <atoms-date-picker
                v-model="from.value"
                label="Tanggal"
                :modal="from.modal"
                :value="from.value"
                @change="
                  (modal, value) => {
                    from.modal = modal
                    from.value = value
                  }
                "
              />
              <atoms-date-picker
                v-if="false"
                v-model="to.value"
                label="Sampai Tanggal"
                :modal="to.modal"
                :value="to.value"
                @change="
                  (modal, value) => {
                    to.modal = modal
                    to.value = value
                  }
                "
              />
              <v-btn
                class="mr-0"
                color="primary"
                min-width="100"
                rounded
                :disabled="
                  loadingListShifts ||
                  loadingGetClients ||
                  loadingGetWorkers ||
                  loadingGetAreas ||
                  loadingGetLocations
                "
                @click="getShifts"
              >
                Filter
              </v-btn>
              <br />
              <v-btn class="mt-3" text x-small @click="resetFilter">
                <v-icon left> mdi-refresh </v-icon>
                Reset Filter
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col> 
      <v-col v-if="listShifts.length > 0 || loadingListShifts" cols="12">
        <v-card :loading="loadingListShifts">
          <v-card-title>
            <span
              >Jumlah shift yang ditemukan:
              {{ listShifts && listShifts.length }}</span
            >
          </v-card-title>
          <v-card-text>
            <v-row justify="center">
              <v-col
                v-for="item in listShifts"
                :key="item._id"
                cols="12"
                md="6"
              >
                <v-card class="ma-5 pa-5 mt-4 text-center">
                  <v-row>
                    <v-col class="my-1 text-left" cols="12">
                      <h3>{{ item.title }}</h3>
                      <h4
                        v-if="item.startDate && item.endDate"
                        class="font-weight-medium"
                      >
                        {{ $moment(item.startDate).format("DD/MM/YYYY") }} -
                        {{ $moment(item.endDate).format("DD/MM/YYYY") }}
                      </h4>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="py-0 text-left mb-5" cols="12">
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Perusahaan :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.client && item.client.name }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Area :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.area && item.area.title }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Lokasi :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.location && item.location.name }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Jumlah Pekerja / PHL :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.amount }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Hari Kerja :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ item.quantity }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Gaji per Hari :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{ $idCurrency(item.priceBase) }}
                        </p>
                      </div>
                      <div>
                        <p class="text-h6 font-weight-medium ma-0 pa-0">
                          Pekerja Terdaftar :
                        </p>
                        <p class="text-caption ma-0 pa-0 mb-2 ml-2">
                          {{
                            (item.workerIds &&
                              $addSeparator(item.workerIds.length)) ||
                            "Belum Ada"
                          }}
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider class="my-3" />
                  <v-card-actions
                    class="pa-0"
                    :title="
                      item.workerIds && item.workerIds[0]
                        ? ``
                        : `Belum ada pekerja yang absensi di shift ini`
                    "
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="gray"
                      style="text-transform: unset"
                      title="Bila tidak ada worker maka tidak bisa lihat daftar pekerja"
                      :disabled="
                        item.workerIds && item.workerIds[0] ? false : true
                      "
                      @click="openListWorkers(item)"
                      >Klik untuk lihat daftar pekerja</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col id="displayWorkers" cols="12">
        <v-card v-if="listWorkers.length > 0 || loadingGetWorkers">
          <v-card-title style="display: block" class="mb-2 mt-3">
            <span
              >Terdapat
              {{ selectedShift.workerIds && selectedShift.workerIds.length }}
              pekerja di shift {{ selectedShift.title }}</span
            >
            <br />
            <span
              >Perusahaan :
              {{ selectedShift.client && selectedShift.client.name }}</span
            >
            <br />
            <span
              >Area : {{ selectedShift.area && selectedShift.area.title }}</span
            >
            <br />
            <span
              >Lokasi :
              {{ selectedShift.location && selectedShift.location.name }}</span
            >
          </v-card-title>
          <v-row justify="center">
            <v-col v-for="item in listWorkers" :key="item._id" cols="12" md="6">
              <v-card class="ma-5 pa-5 mt-4 text-center">
                <img
                  class="elevation-6 mt-n12 mb-3 d-inline-block"
                  :src="item.photo"
                  width="128"
                />
                <v-row>
                  <v-col class="py-0 text-center mt-2 mb-5" cols="12">
                    <router-link
                      style="text-decoration: none; color: inherit"
                      :to="`/worker/review-worker/${item._id}`"
                      target="_blank"
                    >
                      <h4
                        style="
                          position: relative;
                          width: fit-content;
                          margin: 0 auto;
                        "
                        title="Lihat Pekerja"
                      >
                        <span>
                          {{ item.fullName }}
                        </span>
                      </h4>
                    </router-link>
                  </v-col>
                  <v-col class="py-0 text-left mb-5" cols="12">
                    <table>
                      <tr>
                        <td>Target Hari Kerja</td>
                        <td class="px-3">:</td>
                        <td>{{ item.targetAttendances }}</td>
                      </tr>
                      <tr>
                        <td>Kehadiran</td>
                        <td class="px-3">:</td>
                        <td>{{ item.totalAttendances }}</td>
                      </tr>
                      <tr>
                        <td>Approved</td>
                        <td class="px-3">:</td>
                        <td>{{ item.approved }}</td>
                      </tr>
                      <tr>
                        <td>Rejected</td>
                        <td class="px-3">:</td>
                        <td>{{ item.rejected }}</td>
                      </tr>
                      <tr>
                        <td>Gaji yang telah diperoleh</td>
                        <td class="px-3">:</td>
                        <td>{{ $idCurrency(item.gainSalary) }}</td>
                      </tr>
                    </table>
                  </v-col>
                </v-row>
                <v-divider class="my-3" />
                <v-card-actions
                  class="pa-0"
                  :title="
                    item.totalAttendances <= 0
                      ? `Pekerja belum melakukan absensi`
                      : ``
                  "
                >
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="gray"
                    style="text-transform: unset"
                    :disabled="item.totalAttendances <= 0"
                    @click="openListAttendances(item)"
                    >Lihat detail absensi</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="loadingGetWorkers" class="d-flex justify-center">
            <v-progress-circular
              class="my-4"
              indeterminate
              color="primary"
              size="30"
            />
          </div>
          <div class="text-center pt-3 pb-6">
            <v-pagination
              v-model="workerPage"
              :length="workerPagesLength"
              :total-visible="7"
            />
          </div>
        </v-card>
      </v-col>
      <v-col id="displayAttendance" cols="12">
        <v-card v-if="attendances.length > 0 || isLoading">
          <v-card-title style="display: block" class="mt-2">
            <span>Detail Absensi</span>
            <br />
            <span>Nama Pekerja : {{ selectedWorker.fullName }}</span>
            <br />
            <span
              >Perusahaan :
              {{ selectedShift.client && selectedShift.client.name }}</span
            >
            <br />
            <span
              >Area : {{ selectedShift.area && selectedShift.area.title }}</span
            >
            <br />
            <span
              >Lokasi :
              {{ selectedShift.location && selectedShift.location.name }}</span
            >
            <br />
            <span>Shift : {{ selectedShift.title }}</span>
          </v-card-title>
          <v-row justify="center">
            <v-col v-for="item in attendances" :key="item._id" cols="12" md="6">
              <v-card class="ma-5 mt-4 text-center">
                <v-card-text class="py-6">
                  <v-list dense>
                    <v-list-item class="mb-5">
                      <v-img
                        v-if="item.phlSelfieMulai"
                        :src="item.phlSelfieMulai"
                        lazy-src="https://picsum.photos/id/11/10/6"
                        class="rounded-sm"
                        width="100"
                      />
                      <div class="d-block ml-3">
                        <div>
                          <p class="mb-0 font-weight-medium">Jam Mulai :</p>
                          <p>
                            {{ item.phlJamMulai }}
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 font-weight-medium">Lokasi Mulai :</p>
                          <p>
                            {{ item.locationIn }}
                          </p>
                        </div>
                      </div>
                    </v-list-item>
                    <v-list-item class="mb-5">
                      <v-img
                        v-if="item.phlSelfieSelesai"
                        :src="item.phlSelfieSelesai"
                        lazy-src="https://picsum.photos/id/11/10/6"
                        class="rounded-sm"
                        width="100"
                      />
                      <div class="d-block ml-3">
                        <div>
                          <p class="mb-0 font-weight-medium">Jam Selesai :</p>
                          <p>
                            {{ item.phlJamSelesai }}
                          </p>
                        </div>
                        <div>
                          <p class="mb-0 font-weight-medium">
                            Lokasi Selesai :
                          </p>
                          <p>
                            {{ item.locationOut }}
                          </p>
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                  <v-list dense>
                    <v-list-item>
                      <h3>Supervisor</h3>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content style="flex: 1">
                        Status :
                      </v-list-item-content>
                      <v-list-item-content style="flex: 2" class="align-end">
                        {{ item.supervisorApproval }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content style="flex: 1">
                        Komentar :
                      </v-list-item-content>
                      <v-list-item-content style="flex: 2" class="align-end">
                        {{ item.supervisorComment }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                  <v-divider></v-divider>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <div v-if="isLoading" class="d-flex justify-center">
            <v-progress-circular
              class="my-4"
              indeterminate
              color="primary"
              size="30"
            />
          </div>
          <div class="text-center pt-3 pb-6">
            <v-pagination
              v-model="attendancePage"
              :length="attendancePagesLength"
              :total-visible="7"
            />
          </div>
        </v-card>
      </v-col>
    </v-row> -->

    <v-slide-x-reverse-transition>
      <organism-shift-assign-worker
        v-if="openShiftAssignment"
        :shift="openShiftAssignment"
        @close="
          () => {
            getShifts()
            openShiftAssignment = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-shift-assigned-workerlist
        v-if="showAssignedWorkerList"
        :shift="showAssignedWorkerList"
        @close="
          () => {
            getShifts()
            showAssignedWorkerList = null
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-shift-workerlist v-if="showWorkerList" :shift="showWorkerList" @close="showWorkerList = null" />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-shift-detail v-if="openDetail" @closed="openDetail = null" :target="openDetail" />
    </v-slide-x-reverse-transition>
  </div>
  <!-- </v-container> -->
</template>

<script>
export default {
  name: 'ReviewShift',
  data() {
    return {
      // clients: [],
      // captains: [],
      // areas: [],
      // locations: [],
      // listWorkers: [],
      // listShifts: [],
      // selectedDay: {},
      // attendances: [],
      // filter: {
      //   clientId: "",
      //   areaId: "",
      //   locationId: "",
      //   dateStart: new Date(),
      //   dateEnd: new Date(),
      // },
      openDetail: null,
      openShiftAssignment: null,
      showAssignedWorkerList: null,
      client: {
        enabled: false,
        value: '',
        item: null,
        term: '',
      },
      areas: {
        items: null,
        value: null,
      },
      locations: {
        items: null,
        value: null,
      },
      from: {
        modal: false,
        value: this.$moment().startOf('month').format('YYYY-MM-DD'),
      },
      to: {
        modal: false,
        value: this.$moment().format('YYYY-MM-DD'),
      },
      loadingListShifts: false,
      totalShift: 0,
      listShifts: null,
      listShiftsPagesLength: 0,
      showWorkerList: null,
      page: 1,
      limit: 5,

      // workerPage: 1,
      // workerPagesLength: 1,
      // attendancePage: 1,
      // attendancePagesLength: 1,
      // selectedShift: {},
      // selectedWorker: {},
      // isLoading: false,
      // isLoadingSubmit: false,
      // loadingXlsx: false,
      // loadingXlsxPercentage: 0,
      // loadingGetClients: false,
      // loadingGetAreas: false,
      // loadingGetLocations: false,
      // loadingGetWorkers: false,

      // from: {
      //   modal: false,
      //   value: this.$moment().startOf("month").format("YYYY-MM-DD"),
      // },
      // to: {
      //   modal: false,
      //   value: this.$moment().format("YYYY-MM-DD"),
      // },
    }
  },
  computed: {
    // client() {
    //   return this.clients.find((item) => item?._id === this.filter?.clientId)
    // },
    // area() {
    //   return this.areas.find((item) => item?._id === this.filter?.areaId)
    // },
    // location() {
    //   return this.locations.find(
    //     (item) => item?._id === this.filter?.locationId
    //   )
    // },
    getAreaItems() {
      return this.areas.items ? this.areas.items : []
    },
    getAreaName: {
      async set(value) {
        this.$store.commit('SET', { loading: true })
        if (value) {
          this.areas.value = this.areas.items ? this.areas.items.find((x) => x.title === value) : null
          if (this.areas.value?._id) {
            const result = await this.$api
              .get('Locations/get', {
                params: {
                  jsonQuery: JSON.stringify({ areaId: this.areas.value?._id }),
                },
              })
              .then((res) => res?.result || [])
            this.locations.items = result
          }
        } else {
          this.areas.value = value
          this.locations.items = null
        }
        this.getLocName = null
        this.$store.commit('SET', { loading: false })
      },
      get() {
        return this.areas.value?.title || this.areas.value || ''
      },
    },
    getLocItems() {
      return this.locations.items ? this.locations.items : []
    },
    getLocName: {
      set(value) {
        if (!value) {
          this.locations.value = value
        } else {
          this.locations.value = this.locations.items ? this.locations.items.find((e) => e.name === value) : null
        }
      },
      get() {
        return this.locations.value?.title || this.locations.value?.name || this.locations.value || ''
      },
    },
    totalShifts() {
      return this.listShifts?.reduce((total, item) => (total += item.jumlahShift), 0) || 0
    },
  },
  watch: {
    async 'client.value'() {
      if (this.client.value) {
        this.$store.commit('SET', { loading: true })
        this.client.item = this.$store.state.client?.data?.find((x) => x.name === this.client.value)
        const data = await this.$api.get(`Areas/getAreas`, {
          params: {
            jsonQuery: JSON.stringify({
              clientId: this.client.item._id,
            }),
            limit: 100,
          },
        })
        this.areas.items = data?.result
        this.getAreaName = this.$status.semua
        this.$store.commit('SET', { loading: false })
      }
    },
    page: {
      handler() {
        this.getShifts()
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$route?.query?._id) {
      this.getShifts()
    }
  },
  methods: {
    async getClients() {
      this.$store.commit('SET', { loading: true })
      const term = { $regex: this.client.term || '', $options: 'i' }
      try {
        await this.$store.dispatch('client/getAll', {
          custom: {
            approved: true, //{ $exists: true }
            $or: [
              {
                name: term,
              },
              {
                'address.streetAddress1': term,
              },
              {
                'contactPerson.name': term,
              },
              {
                'contactPerson.email': term,
              },
              {
                code: term,
              },
            ],
            pipeline: [
              {
                $lookup: {
                  from: 'Areas',
                  localField: '_id',
                  foreignField: 'clientId',
                  as: 'areas',
                },
              },
            ],
          },
          page: 1,
          limit: 0,
        })
        this.client.enabled = true
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET', { loading: false })
      }
    },
    getFilterMongo({ dateStart, dateEnd }) {
      const clientId = this.client.item?._id
      const areaId = this.areas.value?._id
      const locationId = this.locations.value?._id
      // date
      const realDateStart = new Date(new Date(dateStart).setHours(0, 0, 0, 0))
      // query
      const jsonQuery = {
        startDate: {
          $gte: realDateStart,
        },
        pipeline: [
          {
            $sort: {
              startDate: -1,
            },
          },
          {
            $lookup: {
              as: 'client',
              from: 'Clients',
              localField: 'clientId',
              foreignField: '_id',
              pipeline: [
                {
                  $project: {
                    logo: 1,
                    name: 1,
                    address: 1,
                  },
                },
              ],
            },
          },
          {
            $unwind: {
              path: '$client',
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              as: 'quotation',
              from: 'Quotations',
              localField: 'quotationId',
              foreignField: '_id',
              pipeline: [
                {
                  $project: {
                    no: 1,
                  },
                },
              ],
            },
          },
          {
            $unwind: {
              path: '$quotation',
              preserveNullAndEmptyArrays: true,
            },
          },

          // {
          //   $project: {
          //     title: 1,
          //     area: 1,
          //     location: 1,
          //     amount: 1,
          //     quantity: 1,
          //     priceBase: 1,
          //     totalBase: 1,
          //     client: 1,
          //     workerIds: 1,
          //     attendanceIds: 1,
          //     startDate: 1,
          //     endDate: 1,
          //   },
          // },
        ],
      }
      if (this.$route?.query?._id) {
        jsonQuery._id = this.$route?.query?._id
      }
      if (clientId) {
        jsonQuery.clientId = clientId
      }
      if (areaId && areaId !== this.$status.semua) {
        jsonQuery.areaId = areaId
      } else {
        const listAreaId = this.areas.items?.map((item) => item?._id)?.filter((item) => item)
        if (listAreaId?.[0]) {
          jsonQuery.areaId = listAreaId ? { $in: listAreaId } : undefined
        }
      }
      if (locationId && locationId !== this.$status.semua) {
        jsonQuery.locationId = locationId
      } else {
        const listLocationsId = this.locations.items?.map((item) => item?._id)?.filter((item) => item)
        if (listLocationsId?.[0]) {
          jsonQuery.locationId = listLocationsId ? { $in: listLocationsId } : undefined
        }
      }
      return jsonQuery
    },
    async getShifts() {
      this.loadingListShifts = true
      try {
        this.listShifts = []
        const dateStart = new Date(this.from.value)
        const dateEnd = new Date(this.to.value)
        const filterMongo = this.getFilterMongo({ dateStart, dateEnd })

        // console.log(filterMongo)

        const data = await this.$api.get(`QuotationItems/getShifts`, {
          params: {
            jsonQuery: JSON.stringify(filterMongo),
            page: this.page,
            limit: this.limit,
          },
        })
        if (data?.success) {
          const shifts = data?.result || []
          this.listShifts = shifts
          this.listShiftsPagesLength = data?.pagesLength
          console.log(shifts)
        }
      } catch (err) {
        this.$showDialog({
          title: 'Terdapat Kendala',
          body: err?.message || err,
        })
      }
      this.loadingListShifts = false
    },
    // async getClients($event) {
    //   this.loadingGetClients = true
    //   try {
    //     this.clients = []
    //     const clientName = $event.target.value.trim()
    //     const data = await this.$api.get(`Clients/getClients`, {
    //       params: {
    //         jsonQuery: JSON.stringify({
    //           name: clientName
    //             ? { $regex: clientName || "", $options: "i" }
    //             : undefined,
    //           code: { $exists: true, $ne: "" },
    //         }),
    //         limit: 100,
    //       },
    //     })
    //     this.clients = data.result
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.loadingGetClients = false
    // },
    // async getAreas() {
    //   this.loadingGetAreas = true
    //   try {
    //     this.areas = []
    //     this.locations = []
    //     this.filter.areaId = ""
    //     this.filter.locationId = ""
    //     const query = {
    //       clientId: this.filter?.clientId,
    //     }
    //     const data = await this.$api.get(`Areas/getAreas`, {
    //       params: {
    //         jsonQuery: JSON.stringify(query),
    //         limit: 100,
    //       },
    //     })
    //     this.areas = [this.$status.semua].concat(data?.result)
    //     this.getLocations()
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.loadingGetAreas = false
    // },
    // async getLocations() {
    //   this.loadingGetLocations = true
    //   try {
    //     this.locations = []
    //     this.filter.locationId = ""
    //     // retrieve locations
    //     const query = {
    //       areaId: this.filter?.areaId,
    //     }
    //     if (this.filter.areaId === this.$status.semua) {
    //       delete query.areaId
    //     }
    //     const result = await this.$api
    //       .get("Locations/get", {
    //         params: {
    //           jsonQuery: JSON.stringify(query),
    //         },
    //       })
    //       .then((res) => res?.result || [])
    //     this.locations = [this.$status.semua].concat(result)
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.loadingGetLocations = false
    // },
    // async openListWorkers(shift) {
    //   // this.selectedShift = shift
    //   // this.loadingGetWorkers = true
    //   // window.scroll({
    //   //   top:
    //   //     +document.querySelector("#displayWorkers")?.offsetTop + 400 || 1742,
    //   //   behavior: "smooth",
    //   // })
    //   try {
    //     this.listWorkers = []
    //     const data = await this.$api.get(`Workers/getMongo`, {
    //       params: {
    //         jsonQuery: JSON.stringify({
    //           _id: { $in: shift?.workerIds },
    //         }),
    //         page: this.workerPage,
    //         limit: this.limit,
    //       },
    //     })
    //     this.workerPagesLength = data.pagesLength
    //     let workers = data.result
    //     if (workers?.[0]) {
    //       workers = await Promise.all(
    //         workers.map(async (worker) => {
    //           const targetAttendances = await this.$api
    //             .get("/QuotationItems/get", {
    //               params: {
    //                 jsonQuery: {
    //                   _id: this.selectedShift?._id,
    //                   pipeline: [
    //                     {
    //                       $project: {
    //                         quantity: 1,
    //                       },
    //                     },
    //                   ],
    //                 },
    //               },
    //             })
    //             .then((data) => data?.result?.[0]?.quantity || 0)
    //           const totalAttendances = await this.$api
    //             .get("/Absensi/count", {
    //               params: {
    //                 jsonQuery: {
    //                   shiftId: this.selectedShift?._id,
    //                   ktpPhl: worker?._id,
    //                 },
    //               },
    //             })
    //             .then((data) => data?.result || 0)
    //           const approved = await this.$api
    //             .get("/Absensi/count", {
    //               params: {
    //                 jsonQuery: {
    //                   shiftId: this.selectedShift?._id,
    //                   ktpPhl: worker?._id,
    //                   supervisorApproval: "Approved",
    //                 },
    //               },
    //             })
    //             .then((data) => data?.result || 0)
    //           const rejected = await this.$api
    //             .get("/Absensi/count", {
    //               params: {
    //                 jsonQuery: {
    //                   shiftId: this.selectedShift?._id,
    //                   ktpPhl: worker?._id,
    //                   supervisorApproval: "Rejected",
    //                 },
    //               },
    //             })
    //             .then((data) => data?.result || 0)
    //           const isPaidAttendances = await this.$api
    //             .get("/Absensi/count", {
    //               params: {
    //                 jsonQuery: {
    //                   shiftId: this.selectedShift?._id,
    //                   ktpPhl: worker?._id,
    //                   isPaid: true,
    //                 },
    //               },
    //             })
    //             .then((data) => data?.result || 0)
    //           const gainSalary =
    //             isPaidAttendances * (this?.selectedShift?.priceBase || 0)
    //           const send = {
    //             ...worker,
    //             targetAttendances,
    //             totalAttendances,
    //             approved,
    //             rejected,
    //             gainSalary,
    //           }
    //           return send
    //         })
    //       )
    //       this.listWorkers = workers
    //     }
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.loadingGetWorkers = false
    // },
    // async openListAttendances(worker) {
    //   this.selectedWorker = worker
    //   this.isLoading = true
    //   window.scroll({
    //     top:
    //       +document.querySelector("#displayAttendance")?.offsetTop + 400 ||
    //       1742,
    //     behavior: "smooth",
    //   })
    //   try {
    //     this.attendances = []
    //     const data = await this.$api.get(`Absensi/getMongo`, {
    //       params: {
    //         jsonQuery: JSON.stringify({
    //           shiftId: this.selectedShift._id,
    //           ktpPhl: this.selectedWorker._id,
    //         }),
    //         page: this.attendancePage,
    //         limit: this.limit,
    //       },
    //     })
    //     this.attendancePagesLength = data.pagesLength
    //     this.attendances = data.result.map((item) => {
    //       return {
    //         ...item,
    //         phlSelfieMulai: item?.phlSelfieMulai,
    //         phlJamMulai: item?.phlJamMulai
    //           ? this.$moment(item.phlJamMulai).format("HH:mm")
    //           : "Belum ada",
    //         phlSelfieSelesai: item?.phlSelfieSelesai,
    //         phlJamSelesai: item?.phlJamSelesai
    //           ? this.$moment(item.phlJamSelesai).format("HH:mm")
    //           : "Belum ada",
    //         locationName:
    //           item?.area?.locations?.find((it) => it._id === item.locationId)
    //             ?.name ||
    //           item?.location?.name ||
    //           "",
    //         supervisorApproval: item?.supervisorApproval || "Proses",
    //         supervisorComment: item?.supervisorComment || "Belum Ada",
    //       }
    //     })
    //   } catch (err) {
    //     this.$showDialog({
    //       title: "Terdapat Kendala",
    //       body: err?.response?.data?.message || err?.message,
    //     })
    //   }
    //   this.isLoading = false
    // },
    resetFilter() {
      this.filter = {}
      this.areas = []
      this.locations = []
    },
  },
}
</script>
